<div class="ppn_styles">
  <div>
    <header class="p_header-opps">
      <div class="modal-header">
        <div class="strike"></div>
        <div class="modal-title-oops">
          <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
            alt="Department of Defense logo">
          <span class="modal-header-span">Who is eligible?</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span style="font-size:18px;">Close</span>
          <span aria-hidden="true" style="font-size:25px;">&times;</span>
        </button>
      </div>
    </header>
  </div>
  <div class="modal-body" style="background-color: white;max-height: 300px;overflow-y: scroll;">
    <section class="container">
      <div>
        <p style="text-align: left;">DOD Libraries is committed to connecting our patrons with new ideas, accurate
          information, and memorable experiences. We welcome all library patrons affiliated with the Department of
          Defense and thank them for their service and dedication to our country!
        </p>
      </div>
      <div>
        <p style="text-align: left;"><strong>Authorized Patrons:
          </strong></p>
      </div>
      <ul>
        <li>Active duty military (Army, Marine Corps, Navy, Air Force, and Coast Guard)
        </li>
        <li>Members of the Reserve components and National Guard
        </li>
        <li>Retired military, including retired from the Reserves and National Guard
        </li>
        <li>Medal of Honor recipients and veterans with 100% service-connected disabilities
        </li>
        <li>Eligible family members who are officially sponsored (ID card holder) by authorized patrons in the above categories
        </li>
        <li>DOD and Coast Guard civilians, including appropriated and nonappropriated fund employees, when stationed outside the United States; stateside eligibility contingent upon resource availability 
        </li>
      </ul>
      <div>
      <p style="text-align: left;">*generalized categories; eligibility per DoDI 1015.10</p>
      </div>
    </section>
  </div>