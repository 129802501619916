import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-ssnoops',
  templateUrl: './ssnoops.component.html',
  styleUrls: ['./ssnoops.component.css']
})
export class SSNOopsComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, public router: Router) {}
  @Input() res:any;
  whoIsEligible = false;
  ngOnInit() {
  }
  redirectToEdipi(){
    this.activeModal.close();
  }
  toggle() {
    this.whoIsEligible = !this.whoIsEligible;
  }

}
export class DialogElementsExampleDialog {}