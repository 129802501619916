import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreService } from '../../common/core.service';
import { MWRServiceService } from '../mwrservice.service';
import { environment } from 'src/environments/environment';
import { timeout, map } from 'rxjs/operators';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { SystemConnectivityErrorComponent } from '../system-connectivity-error/system-connectivity-error.component';
import { AuthEligibleComponent } from '../auth-eligible/auth-eligible.component';
import { MyEDILinkComponent } from '../my-edilink/my-edilink.component';
import { EligibilityStatusNComponent } from '../eligibility-status-n/eligibility-status-n.component';
import { OOPSLocalNationalComponent } from '../oops-local-national/oops-local-national.component';
import { NetworkErrorPopupComponent } from '../network-error-popup/network-error-popup.component';
@Component({
  selector: 'app-local-national',
  templateUrl: './local-national.component.html',
  styleUrls: ['./local-national.component.css']
})
export class LocalNationalComponent implements OnInit, OnDestroy {
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  model: any = { sessionId: '', libId: '', surname: '' };
  errorCode = { reasonCode: '', description: '' };
  googleAnalytics: any;
  callbackuri: any;
  localNationalURL;
  searchparam: any;
  post_response: any;
  modalRef: any;
  referrer = '';
  isCoastGuard = 'local-national';
  sessionid: any;
  submitted = false;
  libId = '';
  surname = '';
  timeout;
  constructor(private modalService: NgbModal, private http: HttpClient, private routing: ActivatedRoute,
    private route: Router, private coreService: CoreService, private SpinnerService: NgxSpinnerService, private MWRServiceService: MWRServiceService) {
    this.localNationalURL = environment.localNational;
    this.timeout = environment.timeout;
    this.routing.queryParams
      .subscribe(params => {
        this.sessionid = params['sid'];
        if (!params['sid']) {
         // window.location.href = 'https://uat.dodmwrlibraries.org';
          window.location.href = environment.sidRedirectLink;
        }
      });
     
    // this.routing.queryParams.subscribe(params => {
    //   if (params.sid) {
    //     this.sessionid = params.sid
    //     console.log('param', this.sessionid)
    //   }
    //   else {
    //     this.sessionid = '';
    //   }
    // });
  }
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        // console.log('network on ?', status);
        this.networkStatus = status;
      });

  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }
  toUpperSurname(event: any) {
    this.model.surname = this.model.surname.toUpperCase();
  }
  handleChange() {
    this.isCoastGuard = '/';
    // if (this.searchparam.callback_uri == "undefined" || this.searchparam.callback_uri == undefined) {
    //   this.route.navigate(["/"]);
    // } else {
    //   this.route.navigate(["/"], { queryParams: { callback_uri: decodeURIComponent(this.searchparam.callback_uri) } });
    // }
    this.route.navigate(['/'],{ queryParams: { sid: this.sessionid}});
  }
  ngOnInit() {
    this.searchparam = this.searchToObject();
    this.referrer = this.searchparam['callback_uri'];
    this.sessionid = this.MWRServiceService.sessionId;
    if (typeof (this.sessionid) == "undefined") {
      this.sessionid = '';
    }

    // console.log(this.sessionid);
  }
  /**
   * Getting callback uri
   */
  searchToObject() {
    const pairs = window.location.search.substring(1).split('&'),
      obj: any = {};
    let pair;
    for (const i in pairs) {
      if (pairs[i] === '') { continue; }
      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = (pair[1]);
    }
    return obj;
  }
  onSubmit() {
    this.checkNetworkStatus();
    if (this.networkStatus == false) {
      this.modalRef = this.modalService.open(NetworkErrorPopupComponent, { size: 'lg' });
      return;
    }
    this.submitted = true;
    this.SpinnerService.show();
    this.model.sessionId = this.sessionid;
    // this.model.surname = this.model.surname.toUpperCase();
    // console.log("model", this.model);
    const request = this.http.post(this.localNationalURL, this.model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })

    }).pipe(timeout(this.timeout));
    request.subscribe(res => {
      this.post_response = res;
      this.SpinnerService.hide();
      // console.log("response data", this.post_response);
      if (this.post_response.reasonCode == "L100") {
        window.location.href = environment.ELibraryRedirectLink;
      }
      else if (this.post_response.reasonCode == "S908") {
        this.modalRef = this.modalService.open(OOPSLocalNationalComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.post_response;
      }
      else if (this.post_response.reasonCode == "L902" || this.post_response.reasonCode == "L903" || this.post_response.reasonCode == "L906" || this.post_response.reasonCode == "L909") {
        this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.post_response;
      }
      else {
        this.errorCode.reasonCode = 'A404';
        this.errorCode.description = 'Something went wrong';
        this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.errorCode;
      }
    },
      error => {
        this.SpinnerService.hide();
        console.log(error);
        this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
        if (this.post_response) {
          this.modalRef.componentInstance.res = this.post_response;
        } else {
          this.errorCode.reasonCode = '500';
          this.errorCode.description = 'Internal server error';
          this.modalRef.componentInstance.res = this.errorCode;
        }
      })
  }
  openEligible() {
    this.modalRef = this.modalService.open(AuthEligibleComponent, { size: 'lg' });
  }
  openFindMyID() {
    this.modalRef = this.modalService.open(MyEDILinkComponent, { size: 'lg' });
  }
}
