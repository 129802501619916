import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AuthEligibleComponent } from '../auth-eligible/auth-eligible.component';
import { EligibilityStatusNComponent } from '../eligibility-status-n/eligibility-status-n.component';
import { HttpErrorComponent } from '../http-error/http-error.component';
import { MyEDILinkComponent } from '../my-edilink/my-edilink.component';
import { SSNOopsComponent } from '../ssnoops/ssnoops.component';
import { SSNOOPSL908Component } from '../ssnoopsl908/ssnoopsl908.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { SystemConnectivityErrorComponent } from '../system-connectivity-error/system-connectivity-error.component';
import { MWRServiceService } from '../mwrservice.service';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { timeout, map } from 'rxjs/operators';
import { Helper } from './../helper';
import { PlatformLocation } from '@angular/common';
import { NetworkErrorPopupComponent } from '../network-error-popup/network-error-popup.component';
@Component({
  selector: 'app-ssn',
  templateUrl: './ssn.component.html',
  styleUrls: ['./ssn.component.css']
})
export class SSNComponent implements OnInit, OnDestroy {
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  referrer = '';
  ssn = ''; dob: any; lastName: any; rememberMe: any;
  fourDigits = '^[0-9]{4,4}$';
  validdd = '^[0-9]{1,2}$';
  flag = false;
  invalidDate = false;
  invaliddd = false;
  public months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public monthsSmall = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  public oddMonths = ['jan', 'mar', 'may', 'jul', 'aug', 'oct', 'dec'];
  public evenMonths = ['apr', 'jun', 'sep', 'nov'];
  date: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  y: any = [];
  invalidmy = false;
  dd = '';
  mm = '';
  yy = '';
  service_dob: any;
  model: any = { sessionId: '', dateOfBirth: '', edipiNumber: null, ssn: '' };
  errorCode = { reasonCode: '', description: '' };
  submitted = false;
  fullssn = false;
  ssnvisibility = false;
  ssnfieldtype = 'password';
  callbackuri: any;
  post_response: any;
  elib_endpoint;
  timeout;
  sessionid: any;
  modalRef: any;
  isLocalNational = '/';
  searchparam: any;
  constructor(private http: HttpClient, private route: Router, private modalService: NgbModal, private MWRServiceService: MWRServiceService, private SpinnerService: NgxSpinnerService, location: PlatformLocation,private routing: ActivatedRoute) {
    location.onPopState(() => {
      Helper.isNextStep = false;
    })
    this.elib_endpoint = environment.elib;
    this.timeout = environment.timeout;
    this.routing.queryParams
    .subscribe(params => {
      // console.log('ssn sid:',params['sid']);
      this.sessionid = params['sid'];
      if (!params['sid']) {
       // window.location.href = 'https://uat.dodmwrlibraries.org';
        window.location.href = environment.sidRedirectLink;
      }
    });
    if (this.MWRServiceService.yy != null && this.MWRServiceService.mm != null && this.MWRServiceService.dd != null) {
      if (this.MWRServiceService.yy != null && this.MWRServiceService.mm != null && this.MWRServiceService.dd != null) {
        this.yy = this.MWRServiceService.yy;
        this.mm = this.MWRServiceService.mm;
        this.dd = this.MWRServiceService.dd;
      } else {
        this.yy = '';
        this.mm = '';
        this.dd = '';
      }
      this.model.ssn;
    }
  }
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        // console.log('network on ?', status);
        this.networkStatus = status;
      });

  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }
  searchToObject() {
    const pairs = window.location.search.substring(1).split('&'),
      obj: any = {};
    let pair;
    for (const i in pairs) {
      if (pairs[i] === '') { continue; }
      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = (pair[1]);
    }
    return obj;
  }
  handleChange() {
    this.isLocalNational = 'local-national';
    this.route.navigate(['local-national'], { queryParams: { sid: this.sessionid } });
  }

  ngOnInit() {
    this.searchparam = this.searchToObject();
    this.referrer = this.searchparam['callback_uri'];
    // this.sessionid = this.MWRServiceService.sessionId;
    this.model.ssn;
    this.years();
  }

  /**
  * Get years till current year
  */
  // public years() {
  //   const currentYear = new Date().getFullYear();
  //   const y = [];
  //   for (let i = currentYear; i >= 1900; i--) { y.push('' + i); }
  //   return y;
  // }
  public years() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1900; i--) { this.y.push('' + i); }
    return this.y;
  }

  /**
   * Get month list
   */
  public monthList() {

    //  console.log("clicked");
    if (this.flag === true) {
      const currentMonth = new Date().getMonth();
      const m: any = [];
      this.months.filter(function (value, index, arr) {
        if (index <= currentMonth) {
          m.push(value)
        }
      });
      return m;
    }
    else {
      const m = this.months;
      return m;
    }
  }
  public monthListIcon($event: any) {
    // console.log("clicked");
    this.mm = '';

  }

  /**
   * Get day list
   */

  public days() {
    if (this.flag === true) {
      var currentDate = new Date().getDate();
      // const d = [];

      for (let i = 1; i <= 9; i++) {
        this.date.push('0' + i);
      }
      for (let j = 10; j <= currentDate; j++) {
        this.date.push(j);
      }
      return this.date;
    }
    else {
      // const d = [];
      if (this.mm == "" && this.yy == "") {
        for (let i = 1; i <= 9; i++) {
          this.date.push('0' + i);
        }
        for (let j = 10; j <= 31; j++) {
          this.date.push(j);
        }
        return this.date;
      }
      else if (this.oddMonths.includes(this.mm)) {
        for (let j = 10; j <= 31; j++) {
          this.date.push(j);
        }
        for (let i = 1; i <= 9; i++) {
          this.date.push('0' + i);
        }
        return this.date;
      } else if (this.mm == 'Feb' && (parseInt(this.yy) % 100 === 0) ? (parseInt(this.yy) % 400 === 0)
        : (parseInt(this.yy) % 4 === 0)) {
        for (let j = 10; j <= 29; j++) {
          this.date.push(j);
        }
        for (let i = 1; i <= 9; i++) {
          this.date.push('0' + i);
        }
        return this.date;
      }
      else if (this.evenMonths.includes(this.mm)) {
        for (let j = 10; j <= 30; j++) {
          this.date.push(j);
        }
        for (let i = 1; i <= 9; i++) {
          this.date.push('0' + i);
        }
        return this.date;
      } else {
        for (let j = 10; j <= 28; j++) {
          this.date.push(j);
        }
        for (let i = 1; i <= 9; i++) {
          this.date.push('0' + i);
        }
        return this.date;
      }
    }
  }

  alphabeticmm(event: any): boolean {
    let patt = /^([a-zA-Z])$/;
    let result = patt.test(event.key);
    return result;
  }

  numericOnlydd(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  numericOnlyyy(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }


  /**
   * Changes in years
   * @param year 
   */
  changeYear(year: any) {
    const currentYear = new Date().getFullYear();
    if (year === currentYear.toString()) {
      this.flag = true;
      this.dd = "";
      this.mm = "";
      this.days();
      this.monthList();
    }
    else {
      this.flag = false;
    }
  }

  /**
   * Validation for invalid date of birth
   * @param mm 
   * @param dd 
   * @param yy 
   */
  invalidDob(mm: any, dd: any, yy: any) {
    this.invalidDate = false;
    this.iconhide();
    if (mm == "" || mm == null || (mm.length < 3) || dd == "" || dd == null || (dd.length < 2) || yy == "" || yy == undefined || (yy.length < 4)) {
      this.invalidDate = true;
      this.iconhide();
      return true;
    }
    else if (mm != "" || yy != "" || mm != null || yy != null) {
      if (this.oddMonths.includes(mm.toLowerCase()) && dd > 31) {
        this.invalidDate = true;
        this.iconhide();
        return true;
      } else if (mm.toLowerCase() == 'feb') {
        if (dd > '29') {
          // console.log();
          this.SpinnerService.hide();
          this.invalidDate = true;
          this.iconhide();
          return true;
        }
      }
      else if (this.evenMonths.includes(mm.toLowerCase()) && dd > 30) {
        this.invalidDate = true;
        this.iconhide();
        return true;
      }
    }
  }

  iconhide() {
    if (this.invalidDate == true) {
      let nodes1 = document.querySelectorAll('.e-input-group .e-clear-icon.e-clear-icon-hide, .e-input-group.e-control-wrapper .e-clear-icon.e-clear-icon-hide');
      for (let i = 0; nodes1[i]; i++) {
        let node = nodes1[i];
        var c = (nodes1[i] as HTMLElement).style.marginRight = '10%';
      }
      let nodes = document.querySelectorAll('.e-input-group .e-input-group-icon:last-child, .e-input-group.e-bigger .e-input-group-icon:last-child, .e-input-group .e-input-group-icon.e-bigger:last-child, .e-bigger .e-input-group .e-input-group-icon:last-child, .e-input-group.e-small .e-input-group-icon:last-child, .e-input-group.e-small.e-bigger .e-input-group-icon:last-child, .e-input-group.e-small .e-input-group-icon.e-bigger:last-child, .e-input-group.e-control-wrapper .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child, .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child, .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child, .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child, .e-bigger .e-input-group.e-small .e-input-group-icon:last-child');
      for (let i = 0; nodes[i]; i++) {
        let node = nodes[i];
        var c = (nodes[i] as HTMLElement).style.display = 'none';
      }
    } else {
      (document.querySelector('.e-input-group .e-clear-icon.e-clear-icon-hide, .e-input-group.e-control-wrapper .e-clear-icon.e-clear-icon-hide') as HTMLElement).style.marginRight = '10%';
      let nodes = document.querySelectorAll('.e-input-group .e-input-group-icon:last-child, .e-input-group.e-bigger .e-input-group-icon:last-child, .e-input-group .e-input-group-icon.e-bigger:last-child, .e-bigger .e-input-group .e-input-group-icon:last-child, .e-input-group.e-small .e-input-group-icon:last-child, .e-input-group.e-small.e-bigger .e-input-group-icon:last-child, .e-input-group.e-small .e-input-group-icon.e-bigger:last-child, .e-input-group.e-control-wrapper .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child, .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child, .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child, .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child, .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child, .e-bigger .e-input-group.e-small .e-input-group-icon:last-child');
      for (let i = 0; nodes[i]; i++) {
        let node = nodes[i];
        var c = (nodes[i] as HTMLElement).style.display = 'flex';
      }
    }
  }

  /**
   * Get full ssn
   */
  opengetfullSSN() {
    this.fullssn = true;
    this.submitted = false;
  }

  /**
   * toggle ssn visibility
   * @param item 
   */
  toggleSsnVisibility() {
    this.ssnvisibility = !this.ssnvisibility;
    this.ssnfieldtype = this.ssnvisibility ? 'text' : 'password';
  }


  /**
   * Submit no button details
   * @param fullSSN 
   */
  onSubmit() {
    this.checkNetworkStatus();
    if (this.networkStatus == false) {
      this.modalRef = this.modalService.open(NetworkErrorPopupComponent, { size: 'lg' });
      return;
    }
    // this.invaliddmy();
    this.ssnvisibility = false;
    this.ssnfieldtype = this.ssnvisibility ? 'text' : 'password';
    let d = this.dd || '00';
    if (d.length === 1) { d = '0' + d; }
    let m = (this.monthsSmall.indexOf(this.mm.toLowerCase()) + 1).toString() || '00';//.padStart(2, '0') || '00';
    if (m.length === 1) { m = '0' + m; }
    let y = this.yy || '0000';
    this.model.dateOfBirth = `${y}${m}${d}`;
    this.submitted = true;
    this.SpinnerService.show();
    this.model.sessionId = this.sessionid;
    //console.log("request data is", this.model);
    //console.log('configure timeout value', this.timeout);
    if (this.oddMonths.includes(this.mm.toLowerCase()) && this.dd > '31') {
      this.SpinnerService.hide();
      this.invalidDate = true;
      this.iconhide();
      return true;
    } else if (this.mm.toLowerCase() == 'feb' && this.dd > '29') {
      // console.log();
      this.SpinnerService.hide();
      this.invalidDate = true;
      this.iconhide();
      return true;
    }
    else if (this.evenMonths.includes(this.mm.toLowerCase()) && this.dd > '30') {
      this.SpinnerService.hide();
      this.invalidDate = true;
      this.iconhide();
      return true;
    }
    else {
      const request = this.http.post(this.elib_endpoint, this.model, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(timeout(this.timeout));
      request.subscribe(res => {
        this.post_response = res;
        this.SpinnerService.hide();
        //console.log("response data", this.post_response);
        if (this.post_response.reasonCode == "L907") {
          this.modalRef = this.modalService.open(SSNOopsComponent, { size: 'lg' })
          this.modalRef.componentInstance.res = this.post_response;
        } else if (this.post_response.eligibilityStatus == "Y") {
          window.location.href = environment.ELibraryRedirectLink;
        }
        else if (this.post_response.eligibilityStatus == "N") {
          this.modalRef = this.modalService.open(EligibilityStatusNComponent, { size: 'lg' })
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "S908") {
          // this.route.navigateByUrl('/ssn');
          this.modalRef = this.modalService.open(SSNOOPSL908Component, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L906") {
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L903") {
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L904") {
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L905") {
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L910") {
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L902") {
          this.modalRef = this.modalService.open(EligibilityStatusNComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "S901") {
          this.modalRef = this.modalService.open(EligibilityStatusNComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "S909") {
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L504") {
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else if (this.post_response.reasonCode == "L909") {
          this.modalRef = this.modalService.open(EligibilityStatusNComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.post_response;
        }
        else {
          this.errorCode.reasonCode = 'A404';
          this.errorCode.description = 'Something went wrong';
          this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
          this.modalRef.componentInstance.res = this.errorCode;
        }

      }, error => {
        this.SpinnerService.hide();
        // console.log(error);
        this.modalRef = this.modalService.open(SystemConnectivityErrorComponent, { size: 'lg' });
        if (this.post_response) {
          this.modalRef.componentInstance.res = this.post_response;
        } else {
          this.errorCode.reasonCode = '500';
          this.errorCode.description = 'Internal server error';
          this.modalRef.componentInstance.res = this.errorCode;
        }
      })
    }
  }

  openEligible() {
    const modalRef = this.modalService.open(AuthEligibleComponent, { size: 'lg' });
  }
  openFindMyID() {
    const modalRef = this.modalService.open(MyEDILinkComponent, { size: 'lg' });
  }
}


