import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MWRServiceService } from '../mwrservice.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent implements OnInit {

  sessionId: any;
  length: number = 8;
  result = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  constructor(private route: Router, 
    private MWRServiceService: MWRServiceService
    // private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    let text = "";
    for (let i = 0; i < this.length; i++) {
      text += this.result.charAt(Math.floor(Math.random() * this.result.length));
    }
    this.sessionId = text;
    //console.log('sid c', this.sessionId);

    this.MWRServiceService.sessionId = this.sessionId;

    // this.activatedRoute.params.subscribe((params: Params) => {
    //   if (params.myParam){
    //     this.MWRServiceService.sessionId = this.sessionId;
    //     console.log('ii',this.sessionId)
    //   }
    // });
  }

  navigateToEdipi() {
   
    this.route.navigate(['/edipi'],{ queryParams: { sessionId:  this.sessionId }});
  }

}
