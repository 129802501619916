import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eligibility-status-n',
  templateUrl: './eligibility-status-n.component.html',
  styleUrls: ['./eligibility-status-n.component.css']
})
export class EligibilityStatusNComponent implements OnInit {

  @Input() res: any;
  whoIsEligible = false;
  constructor(public activeModal: NgbActiveModal) {

  }

  toggle() {
    this.whoIsEligible = !this.whoIsEligible;
  }

  ngOnInit() {
  }

}
