<div class="ppn_styles">
  <div>
    <header class="p_header-opps">
      <div class="modal-header">
        <div class="strike"></div>
        <div class="modal-title-oops">
          <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
            alt="Department of Defense logo">
          <span class="modal-header-span">Where can I find my EDI-PI?</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span style="font-size:18px;">Close</span>
          <span aria-hidden="true" style="font-size:25px;">&times;</span>
        </button>
      </div>
    </header>
  </div>
  <div class="modal-body" style="background-color: white;">
    <section class="container">
      <span>
        <strong>EDI-PI is the ten digit number located on the back or front of your Government issued Armed Forces ID.</strong>
      </span>
      <img class="text-center img-fluid idcard-edipi" src="./assets/images/thumbnail_findMyEDI.jpg"
        alt="Department of Defense logo">
    </section>
  </div>