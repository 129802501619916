import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MWRServiceService {
  sessionId:any;
  yy:any;
  mm:any;
  dd:any;
  constructor() {
  }
  getData(){

    console.log('service session id:', this.sessionId);
    // console.log('service dob:', this.dob);
  }
}
