import { Injectable,NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MWRServiceService } from './mwrservice.service'
import { Helper } from './helper';
@Injectable({
  providedIn: 'root'
})
export class DirectAccessGuardGuard implements CanActivate {
  sessionId;
  constructor(private router: Router, private MWRServiceService: MWRServiceService,public zone: NgZone) {
  this.sessionId = this.MWRServiceService.sessionId;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if (!Helper.isNextStep) {
      this.zone.run(() => {
        this.router.navigate(['']) //you can redirect user to any page here ( Optional )
      })
      return false;  //block navigation
    }
    else {
      return Helper.isNextStep || true;  // allow navigation
    }
  }
}
