<style>
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
</style>
<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
<h1 class="ppn_heading ppn_align--left">Verify Your Eligibility</h1>
<div class="row" style="line-height: 20px">
  <div class="col-md-6 col-sm-6">
    <p class="ppn_type--small ppn_align--left text-left ppn_type--link">
      <a (click)="openEligible()">Learn who is eligible</a>
    </p>
  </div>
  <div class="col-md-6 col-sm-6">
    <p class="ppn_type--small ppn_align--left text-left ppn_type--link findEDI" style="  margin-left: 18%;">
      <a (click)="openFindMyID()">Where can I find my EDI-PI</a>
    </p>
  </div>
</div>

<form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" class="ppn_form_section" novalidate
  autocomplete="nope">
  <div>
    <div class="row" style="margin-top: 5px;">
      <div class="col-md-6 col-lg-6">
        <label style="font-size: 14px;">Are you a
          <b>Local National ?</b>
        </label>
      </div>
      <div class="col-lg-6">
        <div class="local-national-radio">
          <div class="custom-control custom-radio custom-control-inline">
            <input (change)="handleChange()" type="radio" class="custom-control-input" id="defaultInline1" value="false"
              name="inlineDefaultRadiosExample">
            <label class="custom-control-label" for="defaultInline1">No</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input (change)="handleChange()" type="radio" class="custom-control-input" id="defaultInline2" value="true"
              name="inlineDefaultRadiosExample" checked>
            <label class="custom-control-label" for="defaultInline2">Yes</label>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <!-- <div class="ppn_fieldset-group" style="margin-top: 10px;"> -->
      <fieldset class="ppn_fieldset">
        <label>Library Card#</label>

        <input type="text" [(ngModel)]="model.libId" #libId="ngModel" class="form-control ppn_input ppn_input--user"
          [ngClass]="{'is-invalid':f.submitted && libId.invalid}" name="libId" autocomplete="off" maxlength="320"
          content="maximum-scale=1" required />
        <!-- <a class="customtooltip"><i class='fa fa-info-circle info-icon' aria-hidden='true' data-toggle="tooltip"
            placement="top left" rel="tooltip" role="button"
            ngbTooltip="Card number available on front of the Library card issued"></i></a> -->
        <div class="invalid-feedback">
          <div *ngIf="libId.errors">Library Card Number is required</div>
        </div>
      </fieldset>
      <fieldset class="ppn_fieldset">
        <label>Last Name</label>
        <input type="text" class="form-control ppn_input ppn_input--user" [(ngModel)]="model.surname" #surname="ngModel"
          (keydown.Tab)="toUpperSurname($event)" [ngClass]="{'is-invalid':f.submitted && surname.invalid}"
          name="surname" autocomplete="off" required maxlength="40" pattern=".*[^\s].*" content="maximum-scale=1" />
        <!-- <ng-template #htmlContent>
          <span>
            <p>Last name must match your Library database.</p>
          </span>
        </ng-template>
        <a class="customtooltip"><i class='fa fa-info-circle info-icon' aria-hidden='true' data-toggle="tooltip"
            placement="top left" role="button" data-html="true" rel="tooltip" [ngbTooltip]="htmlContent"></i></a> -->

        <div class="invalid-feedback">
          <div *ngIf="surname.errors">Last Name is required</div>
        </div>
      </fieldset>
      <!-- </div> -->

    </div>
    <!-- <i class='fa fa-info-circle info-icon' aria-hidden='true' ngbTooltip="I am Bootstrap Tooltip"></i> -->
    <fieldset class="ppn_fieldset">
      <button type="submit" [ngClass]="{'xisnotok': f.invalid }"
        class="ppn_button ppn_button--primary ppn_button--large">Submit</button>
    </fieldset>
    <p class="ppn_type--small ppn_align--center ppn_heading--caps">
      <img src="./assets/icons/lock.svg" alt="">&nbsp;&nbsp;Secure Authentication
    </p>
  </div>
  <router-outlet></router-outlet>
</form>

<script>
  $(document).ready(function () {

    var toolOptions;
    var toolOptions2;
    var isOS = /iPad|iPhone|iPod/.test(navigator.platform);
    var isAndroid = /(android)/i.test(navigator.userAgent);

    ///////////////////////////////////////// if OS
    if (isOS) {

      toolOptions = {
        animation: false,
        placement: "left",
        container: "body"
      };
      $('.customtooltip').tooltip(toolOptions);

      $('.customtooltip').css('cursor', 'pointer');
      $('body').on("touchstart", function (e) {
        $(".customtooltip").each(function () {
          // hide any open tooltips when the anywhere else in the body is clicked
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.tooltip').has(e.target).length === 0) {
            $(this).tooltip('hide');
          }////end if
        });
      });
    }


    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      })
    })
  });
</script>