<div class="ppn_styles">
    <div>
        <header class="p_header-opps">
            <div class="modal-header">
                <div class="strike"></div>
                <div class="modal-title-oops">
                    <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
                        alt="Department of Defense logo">
                    <span class="modal-header-span">Network Error</span>
                </div>
                <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                    <span style="font-size:18px;">Close</span>
                    <span aria-hidden="true" style="font-size:25px;">&times;</span>
                </button>
            </div>
        </header>

    </div>
    <div class="modal-body" style="background-color: white;">
        <section class="container">
            <div>
                <br>
                <b>Please check your internet connection and try again.</b>
            </div>

        </section>
    </div>