import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ssnoopsl908',
  templateUrl: './ssnoopsl908.component.html',
  styleUrls: ['./ssnoopsl908.component.css']
})
export class SSNOOPSL908Component implements OnInit {
  whoIsEligible = false;
  @Input() res:any;
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }
  toggle() {
    this.whoIsEligible = !this.whoIsEligible;
  }
  
  ngOnInit() {
  }

}
