<div class="ppn_styles">
  <div>
    <header class="p_header-opps">
      <div class="modal-header">
        <div class="strike"></div>
        <div class="modal-title-oops">
          <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
            alt="Department of Defense logo">
          <span class="modal-header-span">OOPS! SOMETHING WENT WRONG</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span style="font-size:18px;">Close</span>
          <span aria-hidden="true" style="font-size:25px;">&times;</span>
        </button>
      </div>
    </header>
  </div>
  <div class="modal-body" style="background-color: white;">
    <section class="container">
      <div>
        <!-- <strong>Our records show </strong> -->
        <p style="color:red; display:inline-block; font-weight: bold;">Something Went Wrong ! Please try again later</p>
      </div>
      <div>
        <strong>Please refer to <a type="button" class="ppn_type--link" (click)="toggle()"
          style="text-decoration: underline; color:black">'Learn who is eligible'</a> to confirm you are eligible. If you
        are, please back out and try again or send your
        service an email along with the error message so they can provide assistance. </strong>
      </div>
      <div>
        <strong>Army </strong><p style="display:inline-block">- usarmy.mwr.library@army.mil</p>
      </div>
      <div>
        <strong>Marine Corps </strong><p style="display:inline-block">- usmclibraries@usmc.mil</p>
      </div>
      <div>
        <strong>Navy </strong><p style="display:inline-block">- nglp@navy.mil</p>
      </div>
      <div>
        <strong>Air Force & Space Force </strong><p style="display:inline-block">- AFSVA.SVPL.aflibraries@us.af.mil</p>
      </div>
      <div>
        <strong>DMDC helpdesk </strong><p style="display:inline-block">1 800-538-9552</p>
      </div>
    </section>
    <section class="container ppn_section ppn_section--withShadow" *ngIf="whoIsEligible" style="margin-top: 10px;">
      <h4 style="color:red; font-weight: bold;margin-top: 3%; padding-top: 25px;">Who is eligible?</h4>
      <div class="modal-body" style="background-color: white;max-height: 300px;overflow-y: scroll;">

        <section class="container">
          <div>
            <p style="text-align: left;">DOD Libraries is committed to connecting our patrons with new ideas, accurate
              information, and memorable experiences. We welcome all library patrons affiliated with the Department of
              Defense and thank them for their service and dedication to our country!
            </p>
          </div>
          <div>
            <p style="text-align: left;"><strong>Authorized Patrons:
              </strong></p>
          </div>
          <ul>
            <li>All current active duty military (Army, Marine Corps, Navy, Air Force, and Coast
              Guard)
            </li>
            <li>All members of the Reserve components and National Guard
            </li>
            <li>Coast Guard Auxiliary Active Duty
            </li>
            <li>All current active duty and retired National Oceanic and Atmospheric Administration
              (NOAA)
            </li>
            <li>All current active duty and retired US Public Health Service (USPHS)
            </li>
            <li>All retired military, including those in the Reserves and National Guard who are
              retirement eligible
            </li>
            <li>All Medal of Honor recipients
            </li>
            <li>All veterans with service-connected disabilities, all veterans who are Purple Heart
              recipients, all veterans who are former prisoners of war, all individuals approved and designated as the
              primary family caregivers of eligible veterans under the Department of Veterans Affairs Program of
              Comprehensive Assistance for Family Caregivers.
            </li>
            <li>Eligible family members who are officially sponsored (ID card holder) by authorized
              patrons in the above categories
            </li>
            <li>All current active duty military (Army, Marine Corps, Navy, Air Force, and Coast
              Guard)
            </li>
            <li>All Department of Defense and Coast Guard civilians, including appropriated funds
              (APF) employees and nonappropriated funds (NAF) employees
            </li>
          </ul>
        </section>
      </div>
    </section>
  </div>
</div>