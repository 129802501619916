<style>
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
</style>
<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
<h1 class="ppn_heading ppn_align--left">Verify Your Eligibility</h1>
<div class="row" style="line-height: 20px">
  <div class="col-md-6 col-sm-6">
    <p class="ppn_type--small ppn_align--left text-left ppn_type--link">
      <a (click)="openEligible()">Learn who is eligible</a>
    </p>
  </div>
  <div class="col-md-6 col-sm-6">
    <p class="ppn_type--small ppn_align--left text-left ppn_type--link findEDI" style="  margin-left: 18%;">
      <a (click)="openFindMyID()">Where can I find my EDI-PI</a>
    </p>
  </div>
</div>

<form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" class="ppn_form_section" novalidate
  autocomplete="nope">
  <div>
    <div class="row" style="margin-top: 5px;">
      <div class="col-md-6 col-lg-6">
        <label style="font-size: 14px;">Are you a
          <b>Local National ?</b>
        </label>
      </div>
      <div class="col-lg-6">
        <div class="local-national-radio">
          <div class="form-check form-check-inline custom-control custom-radio custom-control-inline">
            <input (change)="handleChange()" type="radio" class="custom-control-input" id="defaultInline1" value="false"
              name="inlineDefaultRadiosExample" checked>
            <label class="custom-control-label" for="defaultInline1">No</label>
          </div>
          <div class="form-check form-check-inline custom-control custom-radio custom-control-inline">
            <input (change)="handleChange()" type="radio" class="custom-control-input" id="defaultInline2" value="true"
              name="inlineDefaultRadiosExample">
            <label class="custom-control-label" for="defaultInline2">Yes</label>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="ppn_fieldset-group" style="width:50%;margin-top: 10px;">
        <fieldset class="ppn_fieldset ssn_field">
          <label style="width: 260px; height: 22px;">DoD Identification Number (i.e.,EDI-PI)</label>
          <button type="button" class="btn showhide" (click)="toggleSsnVisibility()" style="background: #f2f3f4;">
            <i *ngIf="ssnvisibility" class="fa fa-eye"></i>
            <i *ngIf="!ssnvisibility" class="fa fa-eye-slash"></i>
          </button>
          <input [type]="ssnfieldtype" class="form-control ppn_input ppn_input--card " [(ngModel)]="edipi"
            #edipiNumber="ngModel" pattern="[0-9]{10}" [ngClass]="{'is-invalid':f.submitted && edipiNumber.invalid}"
            name="edipiNumber" required autocomplete="new-password" alt="edipino" content="maximum-scale=1"/>
          <div class="invalid-feedback">
            <div>10 digit EDIPI No. is required</div>
          </div>
        </fieldset>
      </div>
      <br>
      <fieldset class="ppn_fieldset dob_field">
        <label>Date of Birth</label>
        <div>
          <div class="row">
            <span class="col-md-4 col-sm-4 left searchDobMm">
              <ejs-combobox id="month" [dataSource]="months" list="month" class="form-control inputpad"
                placeholder="MMM" [ngClass]="{'is-invalid':f.submitted && invalidDob(mm,dd,yy)}" [(ngModel)]="mm"
                name="month" (keypress)="alphabeticmm($event)" #month="ngModel" minlength="3" maxlength="3"
                onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" required content="maximum-scale=1">
              </ejs-combobox>

            </span>
            <span class="col-md-4 col-sm-4 left leftrow searchDobDd">
              <ejs-combobox #day="ngModel" id="day" [dataSource]="date" class="form-control inputpad" placeholder="DD"
                [ngClass]="{'is-invalid':f.submitted && invalidDob(mm,dd,yy)}" [(ngModel)]="dd" name="day"
                (keypress)="numericOnlydd($event)" minlength="2" maxlength="2" onCopy="return false"
                onDrag="return false" onDrop="return false" onPaste="return false" required content="maximum-scale=1"></ejs-combobox>
            </span>
            <span class="col-md-4 col-sm-4 left leftrow searchDobYy">
              <ejs-combobox #year="ngModel" id="year" [dataSource]="y" class="form-control inputpad" placeholder="YYYY"
                [ngClass]="{'is-invalid':f.submitted && invalidDob(mm,dd,yy)}" [(ngModel)]="yy" name="year"
                (keypress)="numericOnlydd($event)" minlength="4" maxlength="4" onCopy="return false"
                onDrag="return false" onDrop="return false" onPaste="return false" required content="maximum-scale=1"></ejs-combobox>
            </span>
          </div>
        </div>

        <div *ngIf="invalidDate == true" class="invalid-feedback" style="display:inline;">Valid date of birth is
          required
        </div>
      </fieldset>
    </div>

    <fieldset class="ppn_fieldset">
      <button type="submit" [ngClass]="{'xisnotok': f.invalid }"
        class="ppn_button ppn_button--primary ppn_button--large">Submit</button>
    </fieldset>
    <p class="ppn_type--small ppn_align--center ppn_heading--caps">
      <img src="./assets/icons/lock.svg" alt="">&nbsp;&nbsp;Secure Authentication
    </p>
  </div>
  <router-outlet></router-outlet>
</form>