import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.css']
})
export class HttpErrorComponent implements OnInit {

  whoIsEligible = false;
  constructor(public activeModal: NgbActiveModal) {

  }

  toggle() {
    this.whoIsEligible = !this.whoIsEligible;
  }

  ngOnInit() {
  }

}
