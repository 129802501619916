import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-system-connectivity-error',
  templateUrl: './system-connectivity-error.component.html',
  styleUrls: ['./system-connectivity-error.component.css']
})
export class SystemConnectivityErrorComponent implements OnInit {
  @Input() res:any;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    //console.log(this.res);
    // if(this.res.status){
    //   console.log(this.res);
    //   this.res.reasonCode = this.res.status;
    //   this.res.description = this.res.statusText;
    // }
  }

}
