import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-oops-local-national',
  templateUrl: './oops-local-national.component.html',
  styleUrls: ['./oops-local-national.component.css']
})
export class OOPSLocalNationalComponent implements OnInit {

  @Input() res: any;
  whoIsEligible = false;
  constructor(public activeModal: NgbActiveModal) {

  }

  toggle() {
    this.whoIsEligible = !this.whoIsEligible;
  }

  ngOnInit(): void {
  }

}
