import { Component } from '@angular/core';
import { Router,ActivatedRoute  } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AuthPrivacyComponent } from './auth-privacy/auth-privacy.component';
import { SSNOopsComponent } from './ssnoops/ssnoops.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ELibraryVarificationAngular';
  category;
  currentPath: any;
  constructor(private modalService: NgbModal, private router: Router, private activatedRouter:ActivatedRoute){
    
    window.location.pathname === '/' ? this.category = "/edipi" : this.category = window.location.pathname;
    this.currentPath = this.category;
    // console.log(this.currentPath);
    // if(this.currentPath == '/edipi'){
    //   this.router.navigate(
    //     ['/'],
    //     { queryParams: { sid: 'mU_rC3UIn2SJwhBXtnYmXbNCRDEymQAoPnBgtAwgBcw' } }
    //   );
    // }
    this.activatedRouter.queryParams
      .subscribe(params => {
        // console.log('params:',params['sid']);
        if(params['sid'] == undefined || params == undefined){
          //window.location.href ='https://uat.dodmwrlibraries.org';
          // console.log(params['sid']);
        }
      })

    
  }
  openPrivacy() {
    const modalRef = this.modalService.open(AuthPrivacyComponent, { size: 'lg' });
  }
}
