import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbActiveModal, NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { EDIPIComponent } from './edipi/edipi.component';
import { SSNComponent } from './ssn/ssn.component';
import { SSNOopsComponent } from './ssnoops/ssnoops.component';
import { AuthPrivacyComponent } from './auth-privacy/auth-privacy.component';
import { AuthEligibleComponent } from './auth-eligible/auth-eligible.component';
import { EligibilityStatusNComponent } from './eligibility-status-n/eligibility-status-n.component';
import { SystemConnectivityErrorComponent } from './system-connectivity-error/system-connectivity-error.component';
import { SSNOOPSL908Component } from './ssnoopsl908/ssnoopsl908.component';
import { HttpErrorComponent } from './http-error/http-error.component';
import { MyEDILinkComponent } from './my-edilink/my-edilink.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { MWRServiceService } from './mwrservice.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { DirectAccessGuardGuard } from './direct-access-guard.guard';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { LocalNationalComponent } from './local-national/local-national.component';
import { OOPSLocalNationalComponent } from './oops-local-national/oops-local-national.component';
import { NetworkErrorPopupComponent } from './network-error-popup/network-error-popup.component';
const routes: Routes = [
  {
    path: '',
    component: EDIPIComponent,
    // canActivate: [DirectAccessGuardGuard]
  },
  {
    path: 'ssn',
    component: SSNComponent,
    canActivate: [DirectAccessGuardGuard],
    // queryParams:{ order: 'popular'}
  },
  {
    path: 'local-national',
    component: LocalNationalComponent,
    // canActivate: [DirectAccessGuardGuard]
  },
  { path: 'ssnoops', component: SSNOopsComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [
    AppComponent,
    EDIPIComponent,
    SSNComponent,
    SSNOopsComponent,
    AuthPrivacyComponent,
    AuthEligibleComponent,
    EligibilityStatusNComponent,
    SystemConnectivityErrorComponent,
    SSNOOPSL908Component,
    HttpErrorComponent,
    MyEDILinkComponent,
    GetStartedComponent,
    LocalNationalComponent,
    OOPSLocalNationalComponent,
    NetworkErrorPopupComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComboBoxModule,
    NgxSpinnerModule,
    RouterModule.forRoot(routes)
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers:
    [
      MWRServiceService,
      NgbActiveModal,
      NgbTooltipConfig
    ],
  bootstrap: [AppComponent],
  entryComponents: [
    AuthPrivacyComponent,
    AuthEligibleComponent,
    EligibilityStatusNComponent,
    SystemConnectivityErrorComponent,
    SSNOOPSL908Component,
    HttpErrorComponent,
    MyEDILinkComponent,
    OOPSLocalNationalComponent,
    NetworkErrorPopupComponent
  ]
})
export class AppModule { }
