import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-privacy',
  templateUrl: './auth-privacy.component.html',
  styleUrls: ['./auth-privacy.component.css']
})
export class AuthPrivacyComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
